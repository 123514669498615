import Intercom from '@intercom/messenger-js-sdk'
import React from 'react'
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import map from './page-resolve-map'
import config from './src/config'

const linkResolver = require('./src/utils/prismic/linkResolver')

export const wrapRootElement = ({ element }) => (
    <PrismicPreviewProvider
        repositoryConfigs={[
            {
                repositoryName: 'tellow',
                linkResolver: (doc) => linkResolver(doc),
                componentResolver: componentResolverFromMap(map),
            },
        ]}
    >
        {element}
    </PrismicPreviewProvider>
)

export const onRouteUpdate = ({ location, prevLocation }) => {
    Intercom({
        app_id: config.intercom.appId,
    })
}
