const IS_PRODUCTION = process.env.NODE_ENV === 'production'
const GCP_DEVELOPMENT = process.env.GATSBY_DEVELOPMENT_BUILD === 'true'

module.exports = {
    graphqlEndpoint: IS_PRODUCTION ? 'https://europe-west1-tellow-nl-production.cloudfunctions.net/graphql/' : 'http://localhost:9000/',
    title: `Het boekhoudpakket voor zzp'ers: Tellow`,
    url: IS_PRODUCTION ? 'https://www.tellow.nl' : 'https://localhost:9000',
    logo: IS_PRODUCTION ? 'https://www.tellow.nl/icons/icon-48x48.png' : './email/logo.png',
    organization: 'Tellow',
    twitter: 'https://twitter.com/tellow_app',
    cookieDomain: IS_PRODUCTION ? '.tellow.nl' : 'localhost',
    rating: {
        klantenvertellen: {
            score: 9.2,
            count: 210,
            best: 10,
            bestRating: 10,
        },
        appstore: 4.4,
        playstore: 4.5,
    },
    links: {
        deeplinkUri: 'tellow://home',
        app: GCP_DEVELOPMENT ? 'https://app.test.tellow.nl/' : 'https://app.tellow.nl/',
        register: GCP_DEVELOPMENT ? 'https://app.test.tellow.nl/registreer' : 'https://app.tellow.nl/registreer',
        stores: {
            apple: 'https://apps.apple.com/nl/app/tellow-voor-freelancers/id1187872148',
            android: 'https://play.google.com/store/apps/details?id=nl.tellow.app&hl=nl&gl=US',
        },
    },
    address: {
        street: 'Danzigerkade 207',
        city: 'Amsterdam',
        country: 'Nederland',
        postalcode: '1013 AP',
    },
    support: {
        email: 'support@tellow.nl',
        phoneNumber: '+31 85 208 3879',
        opensAtTime: '09:00',
        closesAtTime: '21:00',
        phoneSupportClosesAt: '18:00',
    },
    intercom: {
        appId: 'xrg98po3',
    },
}
